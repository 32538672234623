import { Box, IconButton, InputAdornment, MenuItem, Paper, Popper, TextField, useTheme } from "@mui/material";
import RequestInfo from "./RequestInfo";
import AssetsTableActions from "./AssetsTableActions";
import AssetsTable from "./AssetsTable";
import { useState, useRef } from "react";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { ASSETS_DATA } from "../../data/assetsData";
import AssetSearchModal from "./AssetSearchModal";

const assetFilters: any[] = [
    { id: "all", value: "All", label: "All" },
    { id: "sub-1", value: "sub-1", label: "Sub-package 1" },
    { id: "sub-2", value: "sub-2", label: "Sub-package 2" },
    { id: "sub-3", value: "sub-3", label: "Sub-package 3" },
];

const AssetsPage = () => {
    const theme = useTheme();
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [data] = useState<any[]>(ASSETS_DATA);
    const [subPackages, setSubPackages] = useState(assetFilters);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [newPackage, setNewPackage] = useState("");
    const [openSearchPlus, setOpenSearchPlus] = useState(false);
    
    const addSubPackageButtonRef = useRef<HTMLButtonElement | null>(null);

    const handleRowSelection = (item: any, details: any) => {
        setSelectedRowIds(item);
    };

    const handleFilterClicked = (val: any) => {
        console.log(val);
    };

    const handleAddSubPackageClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsCreatingNew(false);  // Reset create new state if previously active
    };

    const handleCreateNewClick = () => {
        setIsCreatingNew(true);
    };

    const handleNewPackageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPackage(e.target.value);
    };

    const handleNewPackageSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && newPackage.trim() !== "") {
            const newSubPackage = { id: `sub-${subPackages.length}`, value: newPackage, label: newPackage };
            setSubPackages([...subPackages, newSubPackage]);
            setIsCreatingNew(false);
        }
    };

    const handleClickAway = () => {
        setIsDropdownOpen(false);
    };

    return (
        <Box>
            <RequestInfo />
            <Box sx={{ mt: "20px", padding: theme.spacing(3) }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: selectedRowIds.length < 2 ? "space-between" : "flex-end",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    {selectedRowIds.length < 2 && (
                        <AssetsTableActions filters={subPackages} onFilterItemsAction={handleFilterClicked} onSearch={()=>setOpenSearchPlus(true)} />
                    )}
                    {selectedRowIds.length >= 2 && (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <APIconButton
                                text="Move to trash"
                                isRounded
                                color={"secondary"}
                                icon={<MaterialSymbol icon="delete" />}
                                onClick={() => {}}
                            />
                            <Box ref={addSubPackageButtonRef}>
                                <APIconButton
                                    text="Add sub-package"
                                    isRounded
                                    whiteBackground
                                    icon={<MaterialSymbol icon="add" />}
                                    onClick={handleAddSubPackageClick}
                                />
                            </Box>
                            <Popper
                                open={isDropdownOpen}
                                anchorEl={addSubPackageButtonRef.current}
                                placement="bottom-start"
                                disablePortal
                                sx={{ zIndex: 1 }}
                            >
                                <Paper sx={{borderRadius:'16px',p:2}}>
                                    <Box sx={{ minWidth: 183, borderRadius:'16px' }}>
                                        {subPackages.filter((pkg)=>pkg.value!=='All').map((pkg) => (
                                            <MenuItem key={pkg.id} value={pkg.value} onClick={()=>handleClickAway()}>
                                                {pkg.label}
                                            </MenuItem>
                                        ))}
                                        {!isCreatingNew && <MenuItem sx={{backgroundColor:'#EBF1FC', borderRadius:'8px'}} onClick={handleCreateNewClick}>Create new</MenuItem>}
                                        {isCreatingNew && <MenuItem sx={{}}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            size="small"
                                            placeholder="Enter sub-package name"
                                            value={newPackage}
                                            onChange={handleNewPackageChange}
                                            onKeyDown={handleNewPackageSubmit}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handleNewPackageSubmit({ key: "Enter" } as React.KeyboardEvent<HTMLInputElement>)}>
                                                            <MaterialSymbol icon="keyboard_return" /> {/* Enter icon */}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        </MenuItem>}
                                    </Box>
                                </Paper>
                            </Popper>
                            <APIconButton
                                text={"Report"}
                                onClick={() => {}}
                                icon={
                                    <Box sx={{ transform: "rotate(-45deg)" }}>
                                        <MaterialSymbol icon="send" />
                                    </Box>
                                }
                            />
                        </Box>
                    )}
                </Box>
                <Box sx={{ mb: 1 }} />
                <Box sx={{ height: "100%" }}>
                    <AssetsTable onRowSelection={handleRowSelection} data={data} />
                </Box>
            </Box>
            <AssetSearchModal open={openSearchPlus} onClose={()=>setOpenSearchPlus(false)} onSubmit={(val:any)=>{
                // const res = val.filters.map((item:any)=>({[item.field.value]: item.value}));
                // console.log('res: ',res);
                setOpenSearchPlus(false);
            }}/>
        </Box>
    );
};

export default AssetsPage;
