import { Box, Button, IconButton } from "@mui/material";
import APDivider from "../../components/APDivider";
import { LabelInput, PasswordInput2 } from "../../components/inputs";
import { useForm } from "react-hook-form";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useState } from "react";
import { isRequired } from "../../utils/validationRules";
import { useAuthStore } from "../auth/authStore";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
    const navigate = useNavigate();
  const {authUser} = useAuthStore();
  const { control, handleSubmit } = useForm({
    defaultValues:{
        id: authUser?.id,
        name: authUser?.name,
        email: authUser?.email,
        role: authUser?.role
    }
  });
  const { control: passwordControl, handleSubmit: handlePasswordSubmit } = useForm();

  const [imagePreview, setImagePreview] = useState<string>("/assets/images/person-placeholder.png");
  const [imageFile, setImageFile] = useState<File | null>(null);

  const onSubmitProfile = (data: any) => {
    // Handle profile update
    const formData = new FormData();
    
    // Append form data
    formData.append("name", data.name);
    formData.append("email", data.email);
    
    // Append image file if it exists
    if (imageFile) {
      formData.append("image", imageFile); // Append image file
    }

    // Handle profile update
    console.log("Profile updated with form data: ", formData.values());
  };

  const onSubmitPassword = (data: any) => {
    // Handle password update
    console.log("Password updated with data: ", data);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string); // Update image preview
      };
      reader.readAsDataURL(file); // Read the file as a data URL
      setImageFile(file); // Store the image file in state
    }
  };

  const handlePasswordFromSubmit = () => {
    handlePasswordSubmit(onSubmitPassword)()
  }
  const handleProfileFromSubmit = () => {
    handleSubmit(onSubmitProfile)();
  }

  const handleCancel = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box sx={{ padding: (theme) => theme.spacing(3) }}>
      {/* Main form for profile update */}
      <form>
        {/* Form contents */}
        <Box sx={{ mb: "60px", position: "relative", width: "150px", height: "150px" }}>
          <Box sx={{ width: "100%", height: "100%", borderRadius: "100%", overflow: "hidden" }}>
            <img
              src={imagePreview}
              alt="Profile"
              style={{ width: "100%", height: "100%", borderRadius: "100%", objectFit: "cover" }}
            />
          </Box>
          {/* Icon to update image */}
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
            }}
            component="label"
          >
            <CameraAltIcon />
            {/* Hidden input to upload new image */}
            <input type="file" hidden onChange={handleImageChange} accept="image/*" />
          </IconButton>
        </Box>

        <Box>
          <APDivider text="Bio" />
          <Box sx={{ display: "flex", width: "24.2%", gap: 3, paddingTop: "15px" }}>
            <LabelInput
              id="name"
              label="Full name"
              name="name"
              control={control}
              rules={{ validate: { isRequired } }}
            />
          </Box>
        </Box>

        <Box>
          <APDivider text="Work info" />
          <Box sx={{ display: "flex", width: "50%", gap: 3, paddingTop: "15px" }}>
            <LabelInput
              id="email"
              label="Email address"
              name="email"
              control={control}
              rules={{ validate: { isRequired } }}
            />
            <LabelInput
              id="role"
              label="Role"
              name="role"
              disabled
              control={control}
              rules={{ validate: { isRequired } }}
            />
          </Box>
        </Box>
      </form>

      {/* Separate form for updating the password */}
      <form>
        <Box sx={{ mt: 5 }}>
          <APDivider text="Security" />
          <Box sx={{ display: "flex", width: "75.7%", gap: 3, paddingTop: "15px" }}>
            <PasswordInput2
              id="currentPassword"
              label="Current password"
              name="currentPassword"
              control={passwordControl}
              rules={{ validate: { isRequired } }}
            />
            <PasswordInput2 
                id="newPassword" 
                label="New password" 
                name="newPassword" 
                control={passwordControl}
                rules={{ validate: { isRequired } }}
            />
            <PasswordInput2
              id="confirmPassword"
              label="Confirm password"
              name="confirmPassword"
              control={passwordControl}
              rules={{ validate: { isRequired } }}
            />
          </Box>
          <Box sx={{ width: "12%", marginTop: "20px" }}>
            <APIconButton 
                text={"Update Password"} 
                icon={<MaterialSymbol icon="check" />} 
                onClick={handlePasswordFromSubmit}
            />
          </Box>
        </Box>
      </form>

      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "40px", width: "18%" }}>
        <Box sx={{ width: "60%" }}>
          <Button variant="contained" onClick={handleProfileFromSubmit} color="primary" fullWidth>
            Update
          </Button>
        </Box>
        <Button variant="outlined" sx={{ ml: 1 }} onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ProfilePage;
