import { Box } from "@mui/material"
import { useFetchOrganizations } from "../../hooks/useFetchOrganizations";
import { useCallback, useEffect, useState } from "react";
import { useGeneralStore } from "../../generaStore";
import APTableFilter from "../../components/table/APTableFilter";
import OrganizationForm from "./OrganizationForm";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import OrganizationTable from "./OrganizationTable";

const OrganizationManagementPage: React.FC = () => {
    const setPageTransition = useGeneralStore((state) => state.setIsPageTransition);
    const { isPending: isLoading, isRefetching, data, refetch } = useFetchOrganizations();
    const { data: usersRes } = useFetchUsers();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [prevResults, setPrevResults] = useState<any[] | undefined>(undefined);
  
    const handleNewOrganization = () => setOpenModal(true);
  
    const handleCloseForm = (event: any) => {
      setSelectedRow(null);
      setOpenModal(false);
    };

    const handleSearchResult = useCallback((results: any) => {
      if (results) {
        setTableData(results.map((item: any) => flattenObject(item)));
      } else if (results === undefined && prevResults && prevResults.length === 0) {
          refetch();
      }
      
      // Update the previous results
      setPrevResults(results);
  }, [refetch, prevResults]);
  
    useEffect(() => {
      setPageTransition(isLoading);
      if (!isLoading && data?.data) {
        setTableData(data.data.map((item: any) => flattenObject(item)));
      }
  
      return () => {
        setPageTransition(false);
        setSelectedRow(null);
      };
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isRefetching]);
  
    return (
      <Box sx={{padding:theme=> theme.spacing(3)}}>
        <Box sx={{mb: '12px'}}>
        <APTableFilter
          showAdd
          showSearch
          addText="New organization"
          searchEndpoint="/api/v1/organizations"
          addAction={handleNewOrganization}
          onSearchResult={handleSearchResult}
        />
        </Box>
          <OrganizationTable 
            data={tableData}
            isLoading={isLoading}
            onRefetch={refetch}
            onOpenForm={(row)=>{
              setSelectedRow(row);
              setOpenModal(true);
            }}
          />
        <OrganizationForm
          onClose={handleCloseForm}
          open={openModal}
          users={usersRes?.data ?? []}
          selectedRow={selectedRow}
          refetchOrganizations={refetch}
        />
      </Box>
    );
  };

export default OrganizationManagementPage;