import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Box, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { PasswordInput } from '../../components/inputs';
import { resetPassword, setPassword } from './authQueries';
import { addCustomValidationRules, removeCustomValidationRules } from '../../utils/validators';
import { IResetPasswordForm } from './authInterfaces';
import { useState } from 'react';
import { DefaultButton } from '../../components/buttons';
import { handleError } from '../../utils/errorHandler';
import PasswordValidationChecklist from './PasswordValidationCheck';
import { isUpperCase, isLowerCase, hasNumber, hasSpecialChar, isMinLength } from '../../utils/validationRules';

const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState<IResetPasswordForm>({
    password: '',
    password_confirmation: '',
    token:''
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const cardContent = (() => {
    if(location.pathname.includes('reset-password')){
      return {
        title: 'Set your new password!',
        content: 'Reset your password by entering a new one to complete your account recovery.',
        buttonText: 'Reset Password'
      };
    }
    else if (location.pathname.includes('set-password')){
      return {
        title: 'Welcome, set your new password!',
        content:'Create your new password to finalize your account setup.',
        buttonText:'Set Password'
      };
    }
  })();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Extract the token from the pathname after "/reset-password/"
  const token = location.pathname.replace(/\/(reset-password|set-password)\//, '');

  React.useEffect(() => {
    const { password, password_confirmation } = formData;

    const allValidationsMet =
      isUpperCase(password) === true &&
      isLowerCase(password) === true &&
      hasNumber(password) === true &&
      hasSpecialChar(password) === true &&
      isMinLength(12)(password) === true &&
      (password === password_confirmation || (!password && !password_confirmation));

    setIsFormValid(allValidationsMet);
  }, [formData]);

  React.useEffect(() => {
    addCustomValidationRules(formData);
    return () => {
      removeCustomValidationRules();
    };
  }, [formData]);

  const resetForm = () => {
    formData.password = '';
    formData.password_confirmation = '';
    formData.token = '';
  }
  const resetPasswordMutation = useMutation({
    mutationKey:['reset-password'],
    mutationFn: (formValues: IResetPasswordForm) => resetPassword(formValues),
    onSuccess: (data) => {
      toast.success('Password reset successful');
      resetForm();
      navigate('/login');
    },
    onError: (error: Error) => {
      handleError(error);
      setIsLoading(false);
    }
  });

  const setPasswordMutation = useMutation({
    mutationKey: ['set-password'],
    mutationFn: (formValues: IResetPasswordForm) => setPassword(formValues),
    onSuccess: (data) => {
      toast.success('Password set successfully');
      resetForm();
      navigate('/login');
    },
    onError(error: any){
      handleError(error);
      setIsLoading(false);
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const payload = {
     ...formData,
      token: token
    }
    if (location.pathname.includes('reset-password')) {
      resetPasswordMutation.mutate(payload);
    } else if(location.pathname.includes('set-password')){
      setPasswordMutation.mutate(payload);
    }
  };

  return (
    <Box sx={{width:'100%'}}>
      <Typography component="h1" variant="h6" sx={{ fontWeight: 'bold' }}>
        {cardContent?.title}
      </Typography>
      <Typography variant="body2" sx={{ mt:4,mb: 6 }}>
        {cardContent?.content}
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <PasswordInput
          label="Enter password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          // validators={['required', 'isUpperCase', 'isLowerCase', 'hasNumber', 'hasSpecialChar', 'isMinLength']}
          // errorMessages={[
          //   'Password is required',
          //   'Password must contain at least one uppercase letter',
          //   'Password must contain at least one lowercase letter',
          //   'Password must contain at least one number',
          //   'Password must contain at least one special character',
          //   'Password must be at least 12 characters long'
          // ]}
        />
        <PasswordInput
          label="Confirm password"
          name="password_confirmation"
          value={formData.password_confirmation}
          onChange={handleChange}
          // validators={['required', 'isPasswordMatch']}
          // errorMessages={[
          //   'Confirm password is required', 'Passwords do not match'
          // ]}
        />
          <PasswordValidationChecklist
            password={formData.password}
            password_confirmation={formData.password_confirmation}
          />
        <DefaultButton 
          text={cardContent?.buttonText!}
          type="submit"
          disable={!isFormValid}
          isLoading={isLoading}
        />
      </ValidatorForm>
    </Box>
  );
};

export default ResetPasswordForm;
