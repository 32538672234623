import * as React from 'react';
import { Box, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Control, Controller } from 'react-hook-form';

interface StyledCheckboxProps {
  size?: 'small' | 'medium';
}

const StyledCheckbox = styled((props: StyledCheckboxProps & React.ComponentProps<typeof Checkbox>) => (
  <Checkbox {...props} />
))(({ size }) => ({
  color: '#4F4F4F',
  '&.Mui-checked': {
    color: '#007BFF',
  },
  '& .MuiSvgIcon-root': {
    fontSize: size === 'small' ? '1.2rem' : '1.5rem',
  },
}));

interface LabeledCheckboxProps {
  id?: string;
  label?: string;
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium';
  defaultValue?: boolean[];
  rules?: object;
  options: Array<{ label: string; value: string }>;
  layout?: 'row' | 'column';
}

const LabelCheckboxInput: React.FC<LabeledCheckboxProps> = ({
  id, label, name, size = 'medium', control, defaultValue = [], rules, options, layout = 'row'
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      {label && (
        <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize: '12px', color: '#4F4F4F' }}>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: layout === 'row' ? 'row' : 'column',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <Box sx={{ display: 'flex', flexDirection: layout }}>
                {options.map((option, index) => (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <StyledCheckbox
                        size={size}
                        id={option.value}
                        checked={field.value?.includes(option.value) || false}
                        onChange={(_, checked) => {
                          const updatedValue = checked
                            ? [...(field.value || []), option.value] // Add the option value when checked
                            : field.value?.filter((item: string) => item !== option.value);
                          field.onChange(updatedValue);
                        }}
                        sx={{
                          color: fieldState.error ? 'red' : primaryColor,
                          '&.Mui-checked': {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default LabelCheckboxInput;
