import { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import APDivider from '../../components/APDivider';
import { LabelInput, LabelCheckboxInput, RichTextInput, SelectInput } from '../../components/inputs';
// import FileInput from '../../components/inputs/FileInput';
import { EVALUATION_REASONS } from '../../data/selectData';
import ImagePreview from './ImagePreview';

interface FormValues {
  ownerName: string;
  ownerEmail: string;
  location: string;
  reasons: any;
  primaryAppraiser: any;
  collaborators: any;
  fileData: any;
}
const images = [
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png',
  '/assets/images/placeholder.png'
];

const AssetForm = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm<FormValues>();
  // const [resetForm, setResetForm] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);

  const onSubmitWithActionType = (actionType: 'create' | 'createAnother') => {
    return (data: FormValues) => {
      console.log('Form Data:', data);
      
      if (actionType === 'create') {
        console.log('navigate to home');
        navigate('/requests');
      } else if (actionType === 'createAnother') {
        reset();
        // setResetForm(true);
        // setTimeout(() => setResetForm(false), 0);
      }
    };
  };

  const handleFormSubmit = (type: 'create' | 'createAnother') => {
    handleSubmit(onSubmitWithActionType(type))();
  };

  const handleViewAllImages = () => {
    setShowAllImages(true);
  };
  const displayedImages = images.slice(0, 10);

  return (
    <Box sx={{ padding: (theme) => theme.spacing(3) }}>
      <Box>
        <form>
          <Box sx={{display:'flex', gap:3}}>
            <Box sx={{width:'75%'}}>
              <Box>
                <APDivider text="Valuation details" />
                <Box sx={{ paddingTop: '15px' }}>
                  <Box sx={{ display: 'flex', width: '50%', gap: 3, paddingTop: '15px' }}>
                    <LabelCheckboxInput
                      label="Default valuation types"
                      name="valuation-types"
                      control={control}
                      options={[
                        { label: 'List/No asset value', value: 'no-asset' },
                      ]}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%', gap: 3}}>
                    <LabelInput
                      id="flv"
                      label="FLV($)"
                      name="flv"
                      control={control}
                    />
                    <LabelInput
                      id="fmv"
                      label="FMV($)"
                      name="fmv"
                      control={control}
                    />
                    <LabelInput
                      id="olv"
                      label="OLV($)"
                      name="olv"
                      control={control}
                    />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Equipment details" />
                  <Box sx={{ display: 'flex', width: '100%', gap: 3}}>
                    <LabelInput
                      id="year"
                      label="Year"
                      name="year"
                      control={control}
                    />
                    <LabelInput
                      id="make"
                      label="Make"
                      name="make"
                      control={control}
                    />
                    <LabelInput
                      id="model"
                      label="Model"
                      name="model"
                      control={control}
                    />
                  </Box>
                  <Box sx={{ width: '32%'}}>
                    <SelectInput
                      id="asset-type"
                      label="Asset type"
                      name="asset-type"
                      options={EVALUATION_REASONS}
                      control={control}
                    />
                  </Box>
              </Box>

              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Asset location" />
                  <Box sx={{ display: 'flex', width: '100%', gap: 3}}>
                    <LabelInput
                      id="zip"
                      label="Zip"
                      name="zip"
                      control={control}
                    />
                    <LabelInput
                      id="state"
                      label="State"
                      name="state"
                      control={control}
                    />
                    <LabelInput
                      id="city"
                      label="City"
                      name="city"
                      control={control}
                    />
                  </Box>
                  <Box sx={{ width: '32%'}}>
                    <LabelInput
                      id="street"
                      label="Street"
                      name="street"
                      control={control}
                    />
                  </Box>
              </Box>

              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Identification numbers" />
                  <Box sx={{ display: 'flex', width: '100%', gap: 3}}>
                    <LabelInput
                      id="serial_number"
                      label="Serial number"
                      name="serial_number"
                      control={control}
                    />
                    <LabelInput
                      id="vin"
                      label="VIN"
                      name="vin"
                      control={control}
                    />
                  </Box>
              </Box>
              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Service meter reading" />
                  <Box sx={{ display: 'flex', width: '100%', gap: 3}}>
                    <LabelInput
                      id="hours"
                      label="Hours"
                      name="hours"
                      control={control}
                    />
                    <LabelInput
                      id="miles"
                      label="Miles"
                      name="miles"
                      control={control}
                    />
                  </Box>
              </Box>

              <Box sx={{ marginTop: '38px' }}>
                <APDivider text="Notes" />
                <Box sx={{ width: '100%', paddingTop: '15px' }}>
                  <RichTextInput id="notes" name="notes" control={control} />
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleFormSubmit('create')}
                  >
                    Add asset
                  </Button>
                  <Button 
                    onClick={() => navigate('/assets')}
                    variant="outlined" sx={{ ml: 1 }}
                  >
                    Cancel
                  </Button>
                </Box>

                <Button
                  variant="text"
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={() => handleFormSubmit('createAnother')}
                >
                  Save and create another
                </Button>
              </Box>
            </Box>
            <Box sx={{width:'25%'}}>

              <Box sx={{ height: '120px', mt: '12px' }}>
                {/* <FileInput label="Drag and drop folder/images" name="file" control={control} type="image" reset={resetForm} /> */}
                <ImagePreview />
                <Typography sx={{fontSize:'12px', color:'#53575A'}}>PNG, JPEG, GIF, HEIC</Typography>
              </Box>
              <Box sx={{mt:'47px'}}>
                <Grid container rowSpacing={0} columnSpacing={1}>
                  {displayedImages.map((image, index) => (
                    <Grid item xs={6} key={image}>
                      <img src={image} alt={image} width={'100%'}/>
                    </Grid>
                  ))}
                </Grid>
                {images.length > 10 && !showAllImages && (
                  <Box sx={{display:'flex', justifyContent:'center'}}>
                    <Button onClick={handleViewAllImages} variant="text">
                      View all images
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AssetForm;
