export const getAbbreviation = (label: string): string => {
    const words = label.split(' ').filter(Boolean); // Split label by spaces and remove empty strings
  
    if (words.length === 1) {
      // If only one word, return the first character
      return words[0][0].toUpperCase();
    }
  
    if (words.length === 2) {
      // If two words, return the first character of both
      return `${words[0][0]}${words[1][0]}`.toUpperCase();
    }
  
    // If more than two words, return the first character of the first and last words
    return `${words[0][0]}${words[words.length - 1][0]}`.toUpperCase();
  };