export const EVALUATION_REASONS = [
    {
        id:'Bankruptcy',
        label: 'Bankruptcy',
        value: 'Bankruptcy'
    },
    {
        id: 'Estate Planning',
        label: 'Estate Planning',
        value: 'Estate Planning'
    },
    {
        id: 'Financing',
        label: 'Financing',
        value: 'Financing'
    },
    {
        id: 'Inspection',
        label: 'Inspection',
        value: 'Inspection'
    },
    {
        id: 'Insurance',
        label: 'Insurance',
        value: 'Insurance'
    },
    {
        id: 'Litigation',
        label: 'Litigation',
        value: 'Litigation'
    },
    {
        id: 'Loss of Life',
        label: 'Loss of Life',
        value: 'Loss of Life'
    },
    {
        id: 'Retirement',
        label: 'Retirement',
        value: 'Retirement'
    },
    {
        id: 'Trade-In',
        label: 'Trade-In',
        value: 'Trade-In'
    }
];

export const PROCESS_DATA = [
    { id:'normal', label: 'Normal', value: 'normal' },
    { id:'uspap',label: 'USPAP', value: 'uspap' },
]