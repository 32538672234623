import { Box} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import RequestTable from "./RequestTable";
import APTableFilter from "../../components/table/APTableFilter";
import AssetSearchResult from "./AssetSearchResult";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";

import { useFetchRequests } from "../../hooks/useFetchRequests";
import { useAuthStore } from "../auth/authStore";
import { useGeneralStore } from "../../generaStore";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import { deleteRequest } from "./requestQueries";
import { handleError } from "../../utils/errorHandler";
import { STATUS_FILTERS } from "../../Constant";

const userFilters = ['All', 'Assigned to me', 'Shared'];

const RequestsPage = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [data, setData] = useState<any[]>([]);
  const [reqData, setReqData] = useState<any[]>([]);  // Added reqData state
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAssetResultModal, setOpenAssetResultModal] = useState(false);
  const [contentText, setContentText] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const authUser = useAuthStore((state) => state.authUser);
  const setIsPageTransition = useGeneralStore((state) => state.setIsPageTransition);
  const { isPending: requestLoading, data: requestResponse, refetch } = useFetchRequests();
  const { mutate: deleteRequestMutation, isPending: isRequestDeleting } = useMutation({
    mutationFn: (requestId: string) => deleteRequest(requestId),
    onSuccess: () => {
      toast.success("Successfully moved to trash");
      refetch();
      setOpenDeleteDialog(false);
    },
    onError: (error) => handleError(error),
  });

  // Handle filters and row selection
  // const filter = location.state?.filter || "All";

  const applyFilter = (filter: string, dataSet: any[]) =>
    filter === "All" ? dataSet : dataSet.filter((item) => item.status === filter);

  const handleFilterClicked = (filter: string) => setData(applyFilter(filter, reqData));

  const handleOwnerClicked = (filter: string) => {
    const ownerData = reqData.filter(
      (item) => item["organization.owner.name"] === authUser?.name
    );
    const sharedData = reqData.filter(
      (item) => item["organization.owner.name"] !== authUser?.name
    );
    setData(filter === "Shared" ? sharedData : ownerData);
  };

  const handleDeleteRequest = () => {
    if (selectedRowIds.length > 2) {
      setContentText("Are you sure you want to move these requests to trash?");
      setOpenDeleteDialog(true);
    } else {
      deleteRequestMutation(selectedRow?.id);
    }
  };

  const handleSingleDelete = (row: any) => {
    // Set selected row and open the delete confirmation dialog
    setSelectedRow(row);
    setContentText("Are you sure you want to move this request to trash?");
    setOpenDeleteDialog(true);
  };

  const handleSearchResult = useCallback(() => setOpenAssetResultModal(true), []);

  const handleRowSelection = (selectedIds: string[]) => setSelectedRowIds(selectedIds);

  const handleCancelDelete = () => {
    setSelectedRow(null);
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    setIsPageTransition(requestLoading);
    if (!requestLoading && requestResponse?.data) {
      const flattenedData = requestResponse.data.map(flattenObject);
      setData(flattenedData);
      setReqData(flattenedData);  // Update reqData with the fetched request data
    }
    return () => setIsPageTransition(false);
  }, [requestLoading, requestResponse, setIsPageTransition]);

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", justifyContent: selectedRowIds.length < 2 ? "space-between" : "flex-end", alignItems: "center", mb: 2 }}>
        {selectedRowIds.length < 2 ? (
          <APTableFilter
            showAdd
            showFilters
            showStatusFilter
            showSearch
            addText="New request"
            statusFilterItems={STATUS_FILTERS}
            filterItems={userFilters}
            addAction={() => navigate("/new-request")}
            statusFilterAction={handleFilterClicked}
            filterAction={handleOwnerClicked}
            searchEndpoint="/api/search"
            onSearchResult={handleSearchResult}
          />
        ) : (
          <APIconButton
            text="Delete selected"
            isRounded
            color="secondary"
            icon={<MaterialSymbol icon="delete" />}
            onClick={handleDeleteRequest}
          />
        )}
      </Box>

      <RequestTable data={data} isLoading={requestLoading} rowPerPage={10} onRowSelection={handleRowSelection} onDelete={handleSingleDelete} />

      <AssetSearchResult openModal={openAssetResultModal} onModalClose={() => setOpenAssetResultModal(false)} />

      <DeleteConfirmationDialog
        title="Move to trash"
        content={contentText}
        open={openDeleteDialog}
        onCancel={handleCancelDelete}
        onDelete={handleDeleteRequest}
        isDeleting={isRequestDeleting}
      />

      <Outlet />
    </Box>
  );
};

export default RequestsPage;
