import { useNavigate } from "react-router-dom";
import APTable from "../../components/table/APTable";
import { IColDef } from "../../components/table/tableInterfaces";

interface Props{
    isLoading?: boolean;
    data: any[];
    rowPerPage?:number;
    onRowSelection?:(item:any,details:any) => void;
    onDelete?:(row:any)=>void;
}
const RequestTable:React.FC<Props> = ({isLoading, data, rowPerPage, onRowSelection,onDelete}) => {
  const navigate = useNavigate();
    const columns: IColDef[] = [
        // { field: 'id', 
        //   headerName: 'ID',
        // },
        {
          field: 'organization.owner.name',
          headerName: 'Primary contact',
        },
        {
          field: 'customer_name',
          headerName: 'Asset owner',
        },
        {
          field: 'assets',
          headerName: 'Assets',
          filterable:false,
          sortable: false,
        },
        {
          field: 'media_upload',
          headerName: 'Photo/Videos',
          type:'media',
          filterable:false,
          sortable: false,
        },
        {
          field: 'created_at',
          headerName: 'Created on',
          type:'date'
        },
        {
          field: 'updated_at',
          headerName: 'Last edited on',
          type:'date'
        },
        {
          field: 'status',
          headerName: 'Status',
          type: 'status'
        },
        {
          field: 'action',
          headerName: 'Action',
          type: 'action'
        },
      ];

      const actions = [
        {
          label: 'Report',
          action: ()=>{}
        },
        {
          label: 'Edit Request details',
          action: (row:any)=>{
            navigate(`/edit-request/${row.id}`)
          }
        },
        {
          label: 'MOVE TO TRASH',
          action:(row:any)=>{
            if(onDelete) onDelete(row);
          }
        }
      ];

      const clickableFields = ['organization.owner.name','organization.name','assets'];

      const handleRowClick = (row:any) => {
        if(clickableFields.includes(row.field)){
          navigate(`/assets`)
        }
      }

      return (
        <APTable 
          // showCheckbox
          columns={columns} 
          data={data} 
          // isLoading={isLoading} 
          actions={actions} 
          clickableFields={clickableFields}
          onRowClick={handleRowClick}
          rowPerPage={rowPerPage}
          onRowSelection={onRowSelection}
        />
      )
}

export default RequestTable;