import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import APDivider from '../../../components/APDivider';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { LabelCheckboxInput, RadioInput, RichTextInput, SelectInput } from '../../../components/inputs';
import { EVALUATION_REASONS, PROCESS_DATA } from '../../../data/selectData';
import LabelInput from '../../../components/inputs/LabelInput';
import { SubmitHandler, useForm } from 'react-hook-form';
// import { isEmail, isRequired } from '../../../utils/validationRules';
import { APIconButton } from '../../../components/buttons';
import { Delete } from '@mui/icons-material';
import { IUser } from '../../auth/authInterfaces';
import { useFetchUsers } from '../../../hooks/useFetchUsers';
import FileInput from '../../../components/inputs/FileInput';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmationDialog from '../../../components/dialogs/DeleteConfirmationDialog';
import AddViewerForm from './AddViewerForm';
import { IUserForm } from '../../user-management/userInterfaces';
import { RequestFormValues, UpdateRequestVariables } from '../requestInterfaces';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getRequest, updateRequest } from '../requestQueries';
import { toast } from 'react-toastify';
import { handleError } from '../../../utils/errorHandler';


const RequestEditForm = () => {
  // const theme = useTheme();
  const { control, handleSubmit, reset } = useForm<RequestFormValues>();
  const { data: response } = useFetchUsers();
  const navigate = useNavigate();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddViewerDialog, setOpenAddViewerDialog] = useState(false);
  // const [viewer, setViewer] = useState<IUserForm>({});
  
  const { id } = useParams<{ id: string }>(); // Get the request ID from the route

  // Fetch request data using react-query
  const { data: requestData, isLoading, error } = useQuery(
    {
      queryKey:['request', id], 
      queryFn:() => getRequest(id as string), 
      enabled: !!id,
    }
  );

  const { isPending: isUpdating, mutate: updateRequestMutation } = useMutation({
    mutationKey: ['updateRequestMutation'],
    mutationFn: ({ requestId,formValues }: UpdateRequestVariables) =>
      updateRequest(requestId,formValues),
    onSuccess: (res) => {
      if(res.status === 204){
        toast.error('An error occured during request creation');
      }
      else{
        toast.success('Request created successfully');
        navigate('/requests');
      }
    },
    onError: handleError,
  });

  // On form submission
  const onSubmit: SubmitHandler<RequestFormValues> = (data) => {
    console.log('data: ',data)
      let payload: any = {};
  
      (Object.keys(data) as (keyof RequestFormValues)[]).forEach((key) => {
        
        if (data[key] !== undefined) {
          switch (key) {
            case 'estimate_options':
              const mergedEstimateOptions = new Set<string>();
              if (Array.isArray(data[key])) {
                data[key].forEach((option) => mergedEstimateOptions.add(option));
              }
              if (Array.isArray(data.other_estimate_options)) {
                data.other_estimate_options.forEach((option) => mergedEstimateOptions.add(option));
              }
              payload[key] = `{${[...mergedEstimateOptions].join(',')}}`;
              break;
            // case 'process':
            //   // payload[key] = `{${(data[key] || []).join(',')}}`;
            //   payload[key] = data[key].id;
            //   break;
            case 'valuation_reasons':
              payload[key] = data[key].map((item) => item.id);
              break;
            // case 'primary_appraiser':
            //   payload[key] = data[key].id;
            //   break;
            case 'collaborators':
              if (Array.isArray(data[key]) && data[key].length > 0) {
                payload[key] = data[key].map((item) => item.id); // Extracting IDs
              } else {
                payload[key] = data[key] || ''; // Set as empty string if it's an empty array or just return the string
              }
              break;
            case 'fileData':
              payload['assets'] =[]// data[key].tableData;
              break;
            default:
              payload[key] = data[key];
          }
        }
      });
      payload.organization = payload.organization.id;

      delete payload.other_estimate_options;

      console.log(payload);


      updateRequestMutation({formValues:payload,requestId:id!})
     
    // reset();
  };

  // Handle dialog cancel actions
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(false);
    navigate('/requests');
  };

  const handleAddViewerSubmit = (val: IUserForm) => {
    console.log('new user: ', val);
    // setViewer(val);
    setOpenAddViewerDialog(false);
  };

  useEffect(() => {
    if (requestData?.data) {
      const res= {
        ...requestData.data,
        'other_estimate_options':requestData.data.estimate_options.filter((item:string)=>['olv','dkv'].includes(item)),
        // 'process':PROCESS_DATA.filter(item=>requestData.data.process === item.value),
        'valuation_reasons':EVALUATION_REASONS.filter(item=>requestData.data.valuation_reasons.includes(item.id))
      };
      console.log(res);
      // console.log(EVALUATION_REASONS.filter(item=>res.valuation_reasons.includes(item.id)))
      reset(res);
    }
  }, [requestData, reset]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading request data</div>;

  return (
    <Box sx={{ display: 'flex', alignItems: 'start', gap: '25px', width: '100%', overflow: 'auto', padding: theme => theme.spacing(3) }}>
      <Box sx={{ width: '100%', height: '100%', }}>
        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <APDivider text='Who owns this equipment?' />
            <Box sx={{ display: 'flex', width: '50%', gap: 3, paddingTop: '15px' }}>
              <LabelInput
                id="ownerName"
                label="Customer Name*"
                name="customer_name"
                control={control}
                // rules={{ validate: { isRequired } }}
              />
              <LabelInput
                id="ownerEmail"
                label="Customer email*"
                name="customer_email"
                control={control}
                // rules={{ validate: { isRequired, isEmail } }}
              />
            </Box>
          </Box>

          <Box>
            <APDivider text="Valuation details" />
            <Box sx={{ display: 'flex', width: '50%', gap: 3, paddingTop: '15px' }}>
              <LabelCheckboxInput
                label="Default valuation types"
                name="estimate_options"
                control={control}
                options={[
                  { label: 'FLV', value: 'flv' },
                  { label: 'FMV', value: 'fmv' },
                  // { label: 'OLV', value: 'olv' },
                ]}
              />
            </Box>
              <LabelCheckboxInput
                label="Other valuation types (Note: These are not default to your organization)"
                name="other_estimate_options"
                control={control}
                options={[
                  { label: 'OLV', value: 'olv' },
                  { label: 'Desktop valuation', value: 'dkv' }
                ]}
              />
              <RadioInput
                label="Select Process"
                name="process"
                control={control}
                options={PROCESS_DATA}
              />
  
          </Box>

          <Box sx={{ marginTop: '8px' }}>
            <APDivider text='More information' />
            <Box sx={{ display: 'flex', width: '24.5%', paddingTop: '15px' }}>
              <SelectInput
                id='reasons'
                label='Select evaluation reasons'
                name='valuation_reasons'
                options={EVALUATION_REASONS}
                isMultiple
                control={control}
              />
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px' }}>
            <APDivider text='Notes' />
            <Box sx={{ width: '100%', paddingTop: '15px' }}>
              <RichTextInput id='notes' name='notes' control={control} />
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px' }}>
            <APDivider text='Who is managing this request?' />
            <Box sx={{ width: '24.5%', paddingTop: '15px' }}>
              {/* <SelectInput
                id="primaryAppraiser"
                label="Primary appraiser*"
                name="primaryAppraiser"
                options={response?.data.filter((user: IUser) => user.role === 'appraiser').map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                control={control}
                rules={{ validate: { isRequired } }}
              /> */}
              <SelectInput
                id="collaborators"
                label="Collaborators"
                name="collaborators"
                options={response?.data.filter((user: IUser) => user.role === 'admin').map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                control={control}
                isMultiple
              />

              {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <SelectInput
                  id="viewers"
                  label="Viewers"
                  name="viewers"
                  options={response?.data.filter((user: IUser) => user.role === 'guest').map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                  control={control}
                  isMultiple
                />
                <Button
                  onClick={() => setOpenAddViewerDialog(true)}
                  sx={{ mt: '-10px', p: 0, fontSize: '12px', color: theme.palette.primary.main, borderBottom: `1px solid ${theme.palette.primary.main}`, borderRadius: 0 }}
                >
                  Add viewer
                </Button>
              </Box> */}
            </Box>
          </Box>

          <Box sx={{ marginTop: '38px' }}>
            <APDivider text="Add assets" />
            <Box sx={{ height: '120px', paddingTop: '15px' }}>
              <FileInput label="Drag and drop or choose an Excel file" name="file" control={control} type="excel" />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <Box>
              <Button type="submit" variant="contained" color="primary" disabled={isUpdating}>Save update</Button>
              <Button variant="outlined" sx={{ ml: 1 }} onClick={() => navigate('/requests')}>Cancel</Button>
            </Box>

            <Box>
              <APIconButton
                text='Delete Request'
                isRounded={false}
                color={'secondary'}
                icon={<Delete />}
                isDisabled={isUpdating}
                onClick={() => { setOpenDeleteDialog(true); }}
              />
            </Box>
          </Box>
        </ValidatorForm>
      </Box>

      <AddViewerForm open={openAddViewerDialog} onClose={() => setOpenAddViewerDialog(false)} onSubmit={handleAddViewerSubmit} />
      <DeleteConfirmationDialog
        title="Delete request"
        content={'Are you sure you want to delete this request? This action is irreversible.'}
        open={openDeleteDialog}
        onCancel={handleCancelDelete}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default RequestEditForm;
