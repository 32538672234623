import { Box, Modal, Typography } from "@mui/material";
import { APIconButton } from "../../components/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { useState } from "react";
import AssetReseachResultItem from "./request-form/AssetSearchResultItem";

interface Props {
    openModal: boolean;
    onModalClose?: () => void;
}

const AssetSearchResult: React.FC<Props> = ({ openModal, onModalClose }) => {
    const [numbers] = useState(Array.from({ length: 10 }, () => Math.floor(Math.random() * 100) + 1));

    return (
        <Modal open={openModal} onClose={onModalClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '40%',
                    height: '90%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Fixed Header */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: '#FCFCFC',
                        p: '25px',
                        borderRadius: '8px 8px 0px 0px',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <Typography sx={{ fontSize: '24px', color: '#000000' }}>
                        Showing results for 10 (Ford) Assets:
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <APIconButton
                            text={'View all (10)'}
                            icon={<MaterialSymbol icon="arrow_forward_ios" />}
                            type="submit"
                        />
                        <APIconButton
                            text="Cancel"
                            icon={<MaterialSymbol icon="close" />}
                            whiteBackground
                            onClick={onModalClose}
                        />
                    </Box>
                </Box>

                {/* Scrollable Content */}
                <Box
                    sx={{
                        p: '25px',
                        flexGrow: 1,
                        overflowY: 'auto',
                        maxHeight: 'calc(100% - 90px)', // Adjust for header height
                    }}
                >
                    {numbers.map((item: number, index: number) => (
                        <Box key={item} sx={{ mb: '15px' }}>
                            <AssetReseachResultItem
                                images={["/assets/images/asset-1.png","/assets/images/asset-1.png","/assets/images/asset-1.png","/assets/images/asset-1.png","/assets/images/asset-1.png"]}
                                summary={"1984 Ford 600 Lube Truck"}
                                location={"Hayden, CO"}
                                valuationType={"Internal valuation"}
                                serial={"1FREADFFE24GDFSDF253"}
                                milage={"10,311"}
                                description={"5+2, 8.2L cat diesel"}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Modal>
    );
};

export default AssetSearchResult;
