import { Box, Typography, useTheme } from "@mui/material";
import IconText from "../../asset-compare/IconText";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
    images: string[]; // Change from string to array of strings
    summary: string;
    location: string;
    valuationType: string;
    serial: string;
    milage: string;
    description: string;
}

const AssetReseachResultItem: React.FC<Props> = ({ images, summary, location, valuationType, serial, milage, description }) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', gap: '20px' }}>
            <Box sx={{ position: 'relative', width: '230px', height: '192px' }}>
                <img src={images[0]} alt={summary} width={'100%'} height={'100%'} style={{ objectFit: 'cover' }} />
                {images.length > 1 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 8,
                            left: 8,
                            backgroundColor: 'white',
                            color: 'black',
                            borderRadius: '12px',
                            padding: '4px 8px',
                            fontSize: '14px',
                        }}
                    >
                        {`${images.length}`}
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <Box>
                    <Typography sx={{ fontSize: '16px', color: `${theme.palette.text.primary}` }}>{summary}</Typography>
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '6px', gap: '30px' }}>
                        <IconText text={location} icon={'location_on'} />
                        <IconText text={valuationType} icon={'attach_money'} />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '6px', gap: '30px' }}>
                        <IconText text={serial} icon={'directions_car'} />
                        <IconText text={milage} icon={'speed'} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconText text={description} icon={'settings'} />
                    </Box>

                    <Box sx={{display:'flex', backgroundColor:`${theme.palette.black}10`, width:'fit-content', alignItems:'center', p:'4px 8px', borderRadius:'24px', gap:'8px', mt:'20px'}}>
                        <Typography sx={{color:theme.palette.black, fontSize:'14px'}}>Go to request</Typography>
                        <MaterialSymbol icon="arrow_forward_ios" size={16}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AssetReseachResultItem;
