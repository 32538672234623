import { useState } from 'react';
import { Box, Button } from '@mui/material';
import APDivider from '../../../components/APDivider';
import {LabelCheckboxInput, RadioInput, RichTextInput, SelectInput } from '../../../components/inputs';
import { EVALUATION_REASONS, PROCESS_DATA } from '../../../data/selectData';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LabelInput from '../../../components/inputs/LabelInput';
import { useForm } from 'react-hook-form';
import { isEmail, isRequired } from '../../../utils/validationRules';
import { useFetchUsers } from '../../../hooks/useFetchUsers';
import { IUser } from '../../auth/authInterfaces';
import FileInput from '../../../components/inputs/FileInput';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { PostRequestVariables, RequestFormValues } from '../requestInterfaces';
import { postRequest } from '../requestQueries';
import { toast } from 'react-toastify';
import { handleError } from '../../../utils/errorHandler';

const RequestForm = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm<RequestFormValues>();
  const { data: response } = useFetchUsers();
  const [resetForm, setResetForm] = useState(false);

  const { isPending: isLoading, mutate: postRequestMutation } = useMutation({
    mutationKey: ['postRequestMutation'],
    mutationFn: ({ formValues }: PostRequestVariables) =>
      postRequest(formValues),
    onSuccess: (res,variables) => {
      console.log(variables);
      if(res.status === 204){
        toast.error('An error occured during request creation');
      }
      else{
        toast.success('Request created successfully');
        if (variables.actionType === 'create') {
          navigate('/requests');
        } else if (variables.actionType === 'createAnother') {
          reset();
          setResetForm(true);
          setTimeout(() => setResetForm(false), 0);
        }
      }
    },
    onError: handleError,
  });

  const onSubmitWithActionType = (actionType: 'create' | 'createAnother') => {
    return (data: RequestFormValues) => {
      console.log('formdata: ', data);
      let payload: any = {};
  
      (Object.keys(data) as (keyof RequestFormValues)[]).forEach((key) => {
        if (data[key] !== undefined) {
          switch (key) {
            case 'estimate_options':
              const mergedEstimateOptions = new Set<string>();
              if (Array.isArray(data[key])) {
                data[key].forEach((option) => mergedEstimateOptions.add(option));
              }
              if (Array.isArray(data.other_estimate_options)) {
                data.other_estimate_options.forEach((option) => mergedEstimateOptions.add(option));
              }
              payload[key] = `{${[...mergedEstimateOptions].join(',')}}`;
              break;
            case 'process':
              payload[key] = data[key].id;
              break;
            case 'valuation_reasons':
              payload[key] = Array.isArray(data[key])?data[key].map((item) => item.id):data[key];
              break;
            case 'collaborators':
              if (Array.isArray(data[key]) && data[key].length > 0) {
                payload[key] = data[key].map((item) => item.id); // Extracting IDs
              } else {
                payload[key] = data[key] || '';
              }
              break;
            case 'fileData':
              payload['assets'] =[]// data[key].tableData;
              break;
            default:
              payload[key] = data[key];
          }
        }
      });

      delete payload.other_estimate_options;

      postRequestMutation({formValues:{...payload,organization:'01J9M2GJGA5YX0PHBJ2HEW0X1D'},actionType})
     
    };
  };
  

  const handleFormSubmit = (type: 'create' | 'createAnother') => {
    handleSubmit(onSubmitWithActionType(type))();
  };

  return (
    <Box sx={{ padding: (theme) => theme.spacing(3) }}>
      <Box>
        <form>
          {/* Form contents */}
          <Box>
            <APDivider text="Who owns this equipment?" />
            <Box sx={{ display: 'flex', width: '50%', gap: 3, paddingTop: '15px' }}>
              <LabelInput
                id="customerName"
                label="Customer Name*"
                name="customer_name"
                control={control}
                rules={{ validate: { isRequired } }}
              />
              <LabelInput
                id="customerEmail"
                label="Customer email*"
                name="customer_email"
                control={control}
                rules={{ validate: { isRequired, isEmail } }}
              />
            </Box>
          </Box>

          <Box>
            <APDivider text="Valuation details" />
            <Box sx={{ display: 'flex', width: '50%', gap: 3, paddingTop: '15px' }}>
              <LabelCheckboxInput
                label="Default valuation types"
                name="estimate_options"
                control={control}
                options={[
                  { label: 'FLV', value: 'flv' },
                  { label: 'FMV', value: 'fmv' },
                  // { label: 'OLV', value: 'olv' },
                ]}
              />
            </Box>
              <LabelCheckboxInput
                label="Other valuation types (Note: These are not default to your organization)"
                name="other_estimate_options"
                control={control}
                options={[
                  { label: 'OLV', value: 'olv' },
                  { label: 'Desktop valuation', value: 'dkv' }
                ]}
              />
              <RadioInput
                label="Select Process"
                name="process"
                control={control}
                options={PROCESS_DATA}
              />
  
          </Box>
          {/* <Box sx={{ marginTop: '38px' }}>
            <APDivider text="Where is this equipment?" />
            <Box sx={{ display: 'flex', width: '24.5%', paddingTop: '15px' }}>
              <LabelInput
                id="location"
                label="Location*"
                name="location"
                control={control}
                rules={{ validate: { isRequired } }}
              />
            </Box>
          </Box> */}

          <Box sx={{ marginTop: '38px' }}>
            <APDivider text="More information" />
            <Box sx={{ display: 'flex', width: '24.5%', paddingTop: '15px' }}>
              <SelectInput
                id="reasons"
                label="Select evaluation reasons"
                name="valuation_reasons"
                options={EVALUATION_REASONS}
                isMultiple
                control={control}
              />
            </Box>
          </Box>

          <Box sx={{ marginTop: '38px' }}>
            <APDivider text="Notes" />
            <Box sx={{ width: '100%', paddingTop: '15px' }}>
              <RichTextInput id="notes" name="notes" control={control} />
            </Box>
          </Box>

          <Box sx={{ marginTop: '38px' }}>
            <APDivider text="Who is managing this request?" />
            <Box sx={{ width: '24.5%', paddingTop: '15px' }}>
              {/* <SelectInput
                id="primaryAppraiser"
                label="Primary appraiser*"
                name="primary_appraiser"
                options={response?.data.filter((user:IUser)=>user.role === 'appraiser').map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                control={control}
                // rules={{ validate: { isRequired } }}
              /> */}
              <SelectInput
                id="collaborators"
                label="Collaborators"
                name="collaborators"
                options={response?.data.filter((user:IUser)=>user.role === 'admin').map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                control={control}
                isMultiple
              />
            </Box>
          </Box>

          <Box sx={{ marginTop: '38px' }}>
            <APDivider text="Add assets" />
            <Box sx={{ height: '120px', paddingTop: '15px' }}>
              <FileInput label="Drag and drop or choose an Excel file" name="fileData" control={control} type="excel" reset={resetForm} />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleFormSubmit('create')}
                disabled={isLoading}
              >
                Create request
              </Button>
              <Button 
                onClick={() => navigate('/requests')}
                variant="outlined" sx={{ ml: 1 }}
              >
                Cancel
              </Button>
            </Box>

            <Button
              variant="text"
              color="primary"
              disabled={isLoading}
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => handleFormSubmit('createAnother')}
            >
              Save and create another
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default RequestForm;
