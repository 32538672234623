import { Box } from "@mui/material";
import { IColDef } from "../../components/table/tableInterfaces";
import APTable from "../../components/table/APTable";
import { ASSETS_DATA } from "../../data/assetsData";

const colDef: IColDef[] = [
    { field: 'valuationType', headerName: 'Valuation type', type: 'imageText2', filterable:false },
    { field: 'year', headerName: 'Year', filterable:false, width:100 },
    { field: 'make', headerName:'Make', filterable:false},
    { field: 'model', headerName: 'Model', filterable:false},
    { field: 'assetType', headerName: 'Asset type', filterable:false },
    { field: 'serial', headerName: 'Serial',filterable:false },
    { field: 'stickerId', headerName: 'Sticker ID',filterable:false },
    { field: 'myEstimate', headerName: 'Estimate ($)',filterable:false},
];

interface Props{
    onRowClick: (row:any) => void;
}

const AssetCompareTable:React.FC<Props> = ({onRowClick}) => {
    
    return (
        <Box>
            <APTable 
                columns={colDef}
                data={ASSETS_DATA.slice(0,6)}
                onRowClick={onRowClick}
            />
        </Box>
    )
}

export default AssetCompareTable;