import { Box, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState, useMemo } from "react";
import { useAuthStore } from "../features/auth/authStore";
import { useFetchOrganizations } from "../hooks/useFetchOrganizations";

interface Props{
    onSelect?:(val:string)=>void;
}
const OrganizationSelector:React.FC<Props> = ({onSelect}) => {
    const authUser = useAuthStore(state => state.authUser);
    const { data: organizations } = useFetchOrganizations(authUser?.role==='super_admin');
    const [orgMenuAnchorEl, setOrgMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleOrgMenuOpen = (event: any) => setOrgMenuAnchorEl(event.currentTarget);
    const handleOrgMenuClose = (name:string) => {
        if(onSelect)onSelect(name);
        setOrgMenuAnchorEl(null);
    }

    const formattedOrgs = useMemo(() => {
        const allOption = { id: 'all', name: 'All' };
        const orgs = organizations?.data?.map((org: any) => ({
            id: org.id,
            name: org.name,
        })) || [];
        return [allOption, ...orgs];
    }, [organizations]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
                src={authUser?.role === 'super_admin' ? '/assets/images/org.png' : '/assets/images/org-disable.png'} 
                alt='org' 
                width={40} 
                height={40} 
            />
            <Typography sx={{ opacity: authUser?.role === 'super_admin' ? 1 : 0.5 }}>
                {formattedOrgs.length > 0 ? formattedOrgs[0].name : 'No Organization'}
            </Typography>
            {authUser?.role === 'super_admin' && (
                <IconButton onClick={handleOrgMenuOpen}>
                    <ArrowDropDownIcon />
                </IconButton>
            )}
            <Menu anchorEl={orgMenuAnchorEl} open={Boolean(orgMenuAnchorEl)} onClose={handleOrgMenuClose}>
                {formattedOrgs.map((org:any) => (
                    <MenuItem key={org.id} onClick={()=>handleOrgMenuClose(org.name)}>{org.name}</MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default OrganizationSelector;
