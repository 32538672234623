import { Box, Typography } from "@mui/material";
import JoditEditor from "jodit-react";
import { useMemo, useRef } from "react";
import { Control, Controller } from "react-hook-form";

interface Props {
  id?: string;
  label?: string;
  name: string;
  control: Control<any>;
  defaultValue?: string;
  rules?: object;
}

const RichTextInput: React.FC<Props> = ({
  id,
  label,
  name,
  control,
  defaultValue = "",
  rules,
}) => {
  const editorRef = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
      spellcheck: true,
      toolbarSticky: false,
      buttons:
        "bold,italic,underline,strikethrough,ul,ol,fontsize,file,image,table,link,indent,outdent,left,undo,redo,find",
      toolbarAdaptive: false,
      statusbar:false
    }),
    []
  );

  return (
    <Box>
      <Typography
        sx={{ mb: 1, fontSize: "12px", color: "#4F4F4F" }}
      >
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <Box>
            <JoditEditor
              ref={editorRef}
              value={field.value || ""} // Set initial value
              config={config}
              onBlur={() => field.onBlur()} // Handle blur events for validation
              onChange={(newContent) => field.onChange(newContent)} // Sync changes with React Hook Form
            />
            {fieldState.error && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {fieldState.error.message}
              </Typography>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

export default RichTextInput;
