import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
// import JSZip from 'jszip';
import { Modal, Box, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
// import axios from 'axios';
// import DeleteIcon from '@mui/icons-material/Delete';
import { MaterialSymbol } from 'react-material-symbols';

const ImagePreview = () => {
  // const [images, setImages] = useState<File[]>([]);
  const [compressedImages, setCompressedImages] = useState<{ url: string; name: string; size: number }[]>([]);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const imagesPerPage = 8;
  const [currentIndex, setCurrentIndex] = useState(1);

  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute('directory', '');
      ref.current.setAttribute('webkitdirectory', '');
    }
  }, [ref]);

  const onDrop = (acceptedFiles: File[]) => {
    // Handling asynchronous operations without returning a Promise
    (async () => {
      setOpen(true);
      setIsProcessing(true);
      const allCompressedImages: { url: string; name: string; size: number }[] = [];
  
      for (const file of acceptedFiles) {
        const options = { maxSizeMB: 0.1, maxWidthOrHeight: 1024 };
        try {
          const compressedFile = await imageCompression(file, options);
          allCompressedImages.push({
            url: URL.createObjectURL(compressedFile),
            name: compressedFile.name,
            size: compressedFile.size,
          });
        } catch (error) {
          console.error('Compression error:', error);
        }
      }
  
      setCompressedImages(allCompressedImages);
      if (allCompressedImages.length > 0) {
        setMainImage(allCompressedImages[0].url);
      }
  
      setIsProcessing(false);
    })(); // Immediately invoked async function
  };
  

  /*
  const attachAndUpload = async () => {
    const zip = new JSZip();

    for (const image of compressedImages) {
      const response = await fetch(image.url);
      const blob = await response.blob();
      zip.file(image.name, blob);
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const formData = new FormData();
    formData.append('imagesZip', zipBlob, 'images.zip');

    await axios.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    alert('Images uploaded successfully!');
  };
  */

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
    },
    multiple: true,
  });

  const handleDeleteImage = (index: number) => {
    setCompressedImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
  
      // If the main image is deleted, set a new main image
      if (mainImage === prevImages[index].url) {
        setMainImage(updatedImages.length > 0 ? updatedImages[0].url : null);
      }
  
      return updatedImages; // Return the updated images array
    });
  };
  
  const totalImages = compressedImages.length;
  //if page is > 2 check the logic.
  // const currentIndex = (currentPage * imagesPerPage); // + (compressedImages.indexOf(compressedImages.find(image => image.url === mainImage) as any) + 1);

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #000', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} type="file" ref={ref} />
        <p>Drag & drop a folder or click to select images</p>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Center modal`
      >
        <Box
          sx={{
            width: '46%',
            backgroundColor: 'white',
            borderRadius: '8px',
            height: '60vh',
            overflow: 'hidden',
          }}
        >
          {/* Main Image Preview */}
          {isProcessing && (
            <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
              <CircularProgress />
              <Typography variant="body2" color="textSecondary">
                Processing images...
              </Typography>
            </Box>
          )}
          {
            !isProcessing &&
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
                <Box sx={{ width: '70%', height: '100%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                    <img
                        src={mainImage!}
                        alt="Main Display"
                        style={{ width: '100%', height: '80%', objectFit: 'cover' }}
                    />
                </Box>
                <Box sx={{width:'30%', height:'100%',p:'8px 10px'}}>
                    <Box sx={{width:'100%',display:'flex', justifyContent:'space-between', alignItems:'start'}}>
                        <Typography sx={{}}>
                            {compressedImages.length} images
                        </Typography>
                        <MaterialSymbol icon='close' size={12}/>
                    </Box>
                    <Grid container rowSpacing={0} columnSpacing={1}>
                        {compressedImages.slice(currentPage * imagesPerPage, (currentPage + 1) * imagesPerPage).map((image, index) => (
                        <Grid item xs={6} key={image.name}>
                            <Box sx={{ position: 'relative' }}>
                              <button
                              onClick={() => {
                                console.log(index,currentPage);
                                setMainImage(image.url);
                                setCurrentIndex(currentPage>0?index+(imagesPerPage*currentPage)+1:index+1)
                              }}>
                                <img
                                    src={image.url}
                                    alt={`Thumbnail ${index}`}
                                    style={{
                                    width: '120px',
                                    height: '120px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    border: mainImage === image.url ? '1px solid blue' : 'none',
                                    }}
                                    
                                    // tabIndex={0} // Allows keyboard focus
                                    // onKeyDown={(e) => {
                                    //   if (e.key === 'Enter' || e.key === ' ') {
                                    //     setMainImage(image.url); // Handle selection via keyboard
                                    //     setCurrentIndex(currentPage > 0 ? index + (imagesPerPage * currentPage) + 1 : index + 1);
                                    //   }
                                    // }}
                                />
                              </button>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        cursor: 'pointer',
                                        color: 'primary',
                                        backgroundColor:'white',
                                        borderRadius:'100%',
                                        width:'20px',
                                        height:'20px',
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center'
                                    }}
                                    onClick={()=>handleDeleteImage(index)}
                                >
                                    <MaterialSymbol icon='delete' size={16}/>

                                </Box>
                            </Box>
                        </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                        <Button
                        disabled={currentPage === 0}
                        onClick={() => setCurrentPage(prev => prev - 1)}
                        >
                        Previous
                        </Button>
                        <Typography variant="body1" sx={{ mx: 2 }}>
                        {currentIndex}/{compressedImages.length}
                        </Typography>
                        <Button
                        disabled={(currentPage + 1) * imagesPerPage >= totalImages}
                        onClick={() => setCurrentPage(prev => prev + 1)}
                        >
                        Next
                        </Button>
                    </Box>
                </Box>
            </Box>
          }
        </Box>
      </Modal>
    </div>
  );
};

export default ImagePreview;
