function generateRandomId(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

export const TABLE_DATA = [
  { id: generateRandomId(16), primary_contact: 'Simon bankole', asset_owner: 'Alice Smith', assets: 5, media_upload: 2, created_at: '2023-04-12 12:23:45', updated_at: '2024-03-29 06:21:01', status: 'New' },
  { id: generateRandomId(16), primary_contact: 'Jane Roe', asset_owner: 'Bob Johnson', assets: 12, media_upload: 3, created_at: '2023-08-17 04:55:22', updated_at: '2024-01-04 11:43:18', status: 'Completed' },
  { id: generateRandomId(16), primary_contact: 'Simon bankole', asset_owner: 'Charlie Davis', assets: 10, media_upload: 1, created_at: '2024-02-21 15:02:36', updated_at: '2023-07-12 14:37:54', status: 'Awaiting feedback' },
  { id: generateRandomId(16), primary_contact: 'Appraisal QA', asset_owner: 'Diana Miller', assets: 7, media_upload: 4, created_at: '2023-05-30 08:29:13', updated_at: '2023-11-17 21:24:35', status: 'In progress' },
  { id: generateRandomId(16), primary_contact: 'David Evans', asset_owner: 'Frank Wilson', assets: 8, media_upload: 5, created_at: '2024-01-08 13:47:58', updated_at: '2023-06-22 09:11:46', status: 'New' },
  { id: generateRandomId(16), primary_contact: 'Sophia White', asset_owner: 'George Harris', assets: 3, media_upload: 0, created_at: '2024-04-16 23:07:19', updated_at: '2023-10-11 05:13:20', status: 'Completed' },
  { id: generateRandomId(16), primary_contact: 'James Lee', asset_owner: 'Helen Martin', assets: 9, media_upload: 3, created_at: '2023-11-08 02:55:47', updated_at: '2024-06-07 15:16:07', status: 'Awaiting feedback' },
  { id: generateRandomId(16), primary_contact: 'Olivia King', asset_owner: 'Ian Scott', assets: 14, media_upload: 2, created_at: '2023-04-22 19:38:51', updated_at: '2023-12-31 03:45:15', status: 'In progress' },
  { id: generateRandomId(16), primary_contact: 'Derrick Owusu Ansah', asset_owner: 'Jack Thomas', assets: 17, media_upload: 5, created_at: '2023-06-07 07:43:41', updated_at: '2024-05-28 18:57:29', status: 'Awaiting feedback' },
  { id: generateRandomId(16), primary_contact: 'Isabella Hall', asset_owner: 'Karen Baker', assets: 20, media_upload: 5, created_at: '2023-09-11 11:29:39', updated_at: '2024-04-07 23:43:16', status: 'New' },
 { id: generateRandomId(16), primary_contact: 'Isabella Hall', asset_owner: 'Karen Baker', assets: 20, media_upload: 5, created_at: '2023-09-11 11:29:39', updated_at: '2024-04-07 23:43:16', status: 'New', image:'' }
];

  