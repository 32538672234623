import React, { useState } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import APStatus from '../../components/APStatus';

interface Props {
  status: string;
  total_request: number;
  total_assets: number;
  onClick: (filter:string) => void;
}

const DashboardCard: React.FC<Props> = ({ status, total_assets, total_request, onClick }) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  const getStatus = (() => {
    switch (status) {
      case 'New':
        return {
          color: theme.palette.new_color,
          icon: 'not-started',
          bgColor: '#F6F6F6'
        };
      case 'In progress':
        return {
          color: theme.palette.in_progress_color,
          icon: 'in-progress',
          bgColor: '#EDEDF7'
        };
      case 'Awaiting feedback':
        return {
          color: theme.palette.awaiting_feedback_color,
          icon: 'pending-filled',
          bgColor: '#F7F7ED'
        };
      case 'Completed':
        return {
          color: theme.palette.completed_color,
          icon: 'checkmark-filled',
          bgColor: '#EDF7ED'
        };
      default:
        return {}
    }
  })();

  return (
    <Box
      sx={{
        backgroundColor: hover ? 'transparent' : getStatus.bgColor,
        borderRadius: '10px',
        padding: '30px',
        width: '22%',
        height: '230px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: hover ? `1px solid ${theme.palette.divider_color}` : 'none',
        transition: 'background-color 0.3s',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={()=>onClick(status)}
    >
      <APStatus status={status}/>
      <Typography sx={{ 
        fontSize: '48px', 
        fontWeight: 'bold' ,
        color: hover?theme.palette.primary.main:null
        }}>{total_request}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ width: '88px', fontSize: '14px', opacity: 0.6 }}>request with {total_assets} Assets</Typography>
        <Box
          sx={{
            backgroundColor: hover?theme.palette.primary.main:`${theme.palette.primary.main}10`,
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px',
          }}
        >
          {hover ? (
            <ArrowOutwardIcon sx={{ fontSize: '20px', color:theme.palette.white }} />
          ) : (
            <ArrowForwardIcon sx={{ fontSize: '20px' }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCard;
