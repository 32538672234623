import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import UserForm from "./UserForm";
import { useGeneralStore } from "../../generaStore";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import { useMutation } from "@tanstack/react-query";
import { deleteUser } from "./userQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { ITableFilter } from "../../interfaces";
import UserTable from "./UserTable";
import UserTableActions from "./UserTableAction";

const UserManagementPage = () => {
    const setPageTransition = useGeneralStore((state) => state.setIsPageTransition);
    const { data: response, isPending: isLoading, isRefetching, refetch, isError, error } = useFetchUsers();
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [tableData, setTableData] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [tableFilters,setTableFilters] = useState<ITableFilter[]>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [contentText, setContentText] = useState<string>('');
    const [hideActions, setHideActions] = useState(false);
    const [prevResults, setPrevResults] = useState<any[] | undefined>(undefined);
    
    const { isPending: isUserDeleting, mutate: deleteUserMution } = useMutation({
        mutationKey: ['delete-user'],
        mutationFn: (userId: string) => deleteUser(userId),
    });

    const handleStatusChange = (status: string) => {
        if(status === 'all') {
            setTableFilters([]);
            return;
        }
        setTableFilters((prevFilters) => {
            const existingFilter = prevFilters.find(filter => filter.field === 'status');
    
            if (existingFilter) {
                return prevFilters.map(filter =>
                    filter.field === 'status'
                        ? { ...filter, value: status }
                        : filter
                );
            } else {
                return [...prevFilters, { field: 'status', operator: 'equals', value: status }];
            }
        });
    };

    const handleSearchResult = useCallback((results: any) => {
        if (results) {
            setTableData(results);
        } else if (results === undefined && prevResults && prevResults.length === 0) {
            refetch();
        }
        
        // Update the previous results
        setPrevResults(results);
    }, [refetch, prevResults]);

    const handleCloseForm = (event: any) => {
        setSelectedRow(null);
        setOpenForm(false);
      };
    
    const handleOnDelete = () => {
        if(selectedRowIds.length === 2 || selectedRowIds.length >2){
            setOpenDeleteDialog(false);
        }
        else {
            deleteUserMution(selectedRow.id, {
                onSuccess: () => {
                    toast.success('Successfully moved to trash');
                    refetch();
                    setOpenDeleteDialog(false);
                },
                onError: (error) => {
                    handleError(error);
                }
            });
        }
    };

    const handleSingleDelete = (val:any) => {
        setContentText(val.content);
        setSelectedRow(val.row);
        setOpenDeleteDialog(true);
    }

    const handleRowCheck = (item:any,details:any) => {
        if(item.length === 2 || item.lenght > 2 ){
            console.log('hide actions')
            setHideActions(true);
        }
        else if(item.length < 2){
            setHideActions(false)
        }
        setSelectedRowIds(item);
    }

    const handleCancelDelete = () => {
        setSelectedRow(null);
        setOpenDeleteDialog(false);
    };
    const handleBulkDelete = () => {
        setContentText('Are you sure you want to move this selected to trash?');
        setOpenDeleteDialog(true);
    }

    useEffect(() => {
        setPageTransition(isLoading);
        if (!isLoading && response?.data) {
          setTableData(response.data);
        }
        if(isError){
            handleError(error);
        }
    
        return () => {
          setPageTransition(false);
        };
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isLoading, isRefetching, isError]);

    return (
        <Box sx={{ width: '100%', height: '100%', padding:theme=> theme.spacing(3) }}>
            <Box>
                <UserTableActions 
                    selectedRowIds={selectedRowIds}
                    onBulkDelete={handleBulkDelete}
                    onOpenForm={()=>setOpenForm(true)}
                    onStatusChange={handleStatusChange}
                    onSearchResult={handleSearchResult}
                />
                <UserTable  
                    tableData={tableData}
                    tableFilters={tableFilters}
                    isLoading={isLoading}
                    hideActions={hideActions}
                    onRefetch={refetch}
                    onRowCheck={handleRowCheck}
                    onOpenForm={(row)=>{
                        setSelectedRow(row);
                        setOpenForm(true)
                    }}
                    onDelete={handleSingleDelete}

                />
                <UserForm open={openForm} onClose={handleCloseForm} selectedRow={selectedRow} refetchUsers={refetch}/>
                <DeleteConfirmationDialog
                    title="Move to trash"
                    content={contentText}
                    open={openDeleteDialog}
                    onCancel={handleCancelDelete}
                    onDelete={handleOnDelete}
                    isDeleting={isUserDeleting}
                />
            </Box>
        </Box>
    );
};

export default UserManagementPage;
